* {
    margin: 0;
    padding: 0;
}
html {
    font-size: 16px;
}

body {
    background-color: rgb(239, 239, 239);
}

::-webkit-scrollbar {
    width: 5px; /* 设置滚动条宽度 */
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* 设置滚动条背景颜色 */
}

::-webkit-scrollbar-thumb {
    background-color: rgb(148, 214, 252); /* 设置滚动条滑块颜色 */
}
