.Home-aiAnswerCol {
    background-color: rgb(255, 255, 255);
    min-height: 50vh;
    margin-top: 20vh;
    padding: 2vw;
    border-radius: 1vw;
}

.Home-aiAnswerTitle {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    margin: 2vh auto;
}

.Home-aiAnswerTitleIcon {
    background-image: url(https://www.skyler.fun/bigWhite.jpg);
    background-size: cover;
    background-position: center;
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    margin-right: 1vw;
}
.Home-textArea {
    width: 40vw;
    margin-right: 1vw;
}
.Home-questioningButton {
    width: 4vw;
}

@media (max-width: 576px) {
    .Home-aiAnswerCol {
        margin-top: 5.5rem;
    }
    .Home-aiAnswerTitle {
        font-size: 1.1rem;
    }
    .Home-textArea {
        width: 70vw;
    }
    .Home-questioningButton {
        width: 15vw;
    }
}
