* {
    font-size: 1vw;
}
.header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
}
.ant-row {
    height: 8vh;
}

.skylerFont {
    color: rgb(72, 219, 251);
    font-size: 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-style: italic;
}

.navlinkSearch {
    color: rgb(22, 119, 255);
}
.navlinkSearch:hover {
    color: rgb(105, 177, 255);
}

@media (max-width: 500px) {
    * {
        font-size: 0.6rem;
    }
    .Header-search {
        display: none;
    }
    .skylerFont {
        font-size: 10px;
    }
}
